body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
}

* {
  font-family: "Source Sans Pro", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

.mce-ico {
  font-family: "tinymce" !important;
}

#root,
#root > div {
  height: 100vh;
  overflow: hidden;
}

/* #root > div {
  height:  100%;
  overflow: hidden;
} */

.ant-message {
  z-index: 99999;
}

.animated-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
