.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes timer-revisao-excluir {
  from {
    width: 0;
  }
  to {
    width: 135px;
  }
}

.ant-layout {
  /* height: 100%; */
}

.ant-layout-sider {
  /* border-right: 1px solid #d0d0d0; */
  background: #fff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  /* border: 0px !important; */
  /* height: 100%; */
}

.flexCenter {
  display: flex;
  align-items: center;
}
.flexCenterTotal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexContainer {
  display: flex;
}
.flexContainerColumn {
  display: flex;
  flex-direction: column;
}
.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.mt40 {
  margin-top: 40px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 40px;
}
.mr5 {
  margin-right: 5px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}

.btn-prev-default {
  width: 187px;
}

.btn-prev-secundary {
  width: 193px !important;
  height: 49px !important;
  border-radius: 4px !important;
  background-color: #ff8822 !important;
  color: #fff !important;
}

.btn-prev-red {
  height: 49px !important;
  border-radius: 4px !important;
  background-color: #ec1051 !important;
  color: #fff !important;
}

.btn-prev-red:disabled {
  background-color: #f5f5f5 !important;
  color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
}

.btn-prev-secundary-small {
  width: 50px !important;
  height: 49px !important;
  border-radius: 4px !important;
  background-color: #ff8822 !important;
  color: #fff !important;
}

.btn-prev-primary {
  border-radius: 4px !important;
  color: #fff !important;
  width: 193px !important;
  height: 49px !important;
  background-color: #3cb624 !important;
}

.btn-prev-primary-full {
  border-radius: 4px !important;
  color: #fff !important;
  height: 49px !important;
  background-color: #3cb624 !important;
}
.btn-prev-blue-full {
  border-radius: 4px !important;
  color: #fff !important;
  height: 49px !important;
  background-color: #5fa1d8 !important;
}

.btn-prev-blue {
  border-radius: 4px !important;
  color: #fff !important;
  width: 193px !important;
  height: 49px !important;
  background-color: #5fa1d8 !important;
}

.btn-prev-primary:disabled {
  background-color: #ccc !important;
}

.btn-prev {
  border-radius: 4px !important;
  height: 49px !important;
}

.ant-card {
  border-radius: 4px;
}

.ant-breadcrumb {
  list-style: none;
  overflow: hidden;
  font: 18px Sans-Serif;
  color: #fff !important;
  text-align: center;
  width: 100%;
  display: flex;
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.ant-breadcrumb-link.selected {
  background: #5fa1d8;
  color: #fff !important;
}

.ant-breadcrumb-link {
  background: #fff;
  color: rgba(0, 0, 0, 0.33);
  text-decoration: none;
  font-size: 16px;
  padding: 10px 0 10px 65px;
  position: relative;
  display: block;
  float: left;
  text-align: center;
  flex: 1 1 auto;
  width: 356px;
  border-radius: 4px 0px 0px 4px;

  /* margin-left: 27px; */
}

.ant-breadcrumb-link.selected::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  /* border-left: 30px solid #5fa1d8; */
  border-left: 50px solid #5fa1d8;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.ant-breadcrumb-link::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  /* border-left: 30px solid #FFF; */
  border-left: 50px solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.ant-breadcrumb-link::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  /* border-left: 30px solid #f5f7fa; */
  border-left: 50px solid #f5f7fa;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 4px;
  /* left: 100%; */
  left: 362px;
  z-index: 1;
}

.ant-breadcrumb > span:last-child {
  color: #fff !important;
}

.ant-table-thead > tr > th {
  background: #fff !important;
}

.ant-modal-body {
  padding: 24px 34px 34px;
}

.ant-modal-title {
  padding-left: 10px;
}

.ant-modal-content {
  box-shadow: 0 20px 42px rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-mask {
  background-color: #f4f7f9 !important;
  opacity: 0.5;
  /* background-image: url("assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
}

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  color: #fff !important;
}
thead.ant-table-thead th {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.58) !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px !important;
}

.ant-table-tbody > tr > td {
  /* border: 1px solid ; */
}
.ant-table-tbody > tr:hover > td:first-child {
  border-radius: 4px 0px 0px 4px;
  /* border-left: 1px solid #edeada !important; */
}
.ant-table-tbody > tr:hover > td:last-child {
  border-radius: 0px 4px 4px 0px;
  /* border-right: 1px solid #edeada !important; */
}
.ant-table-tbody > tr:not(.excluir-row):hover > td {
  background: #fffce9 !important;
  /* border-bottom: 1px solid #edeada !important;
  border-top: 1px solid #edeada !important; */
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 14px 14px !important;
}

.ant-tabs-nav {
  height: 64px;
  padding-top: 8px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  background: #ff7522;
}
.ant-tabs-ink-bar {
  display: none !important;
}
.ant-tabs-tab-active {
  color: #fff !important;
}
.ant-tabs-tab-active:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #ff7522;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  left: 50%;
  margin-top: -5px;
  margin-left: -8px;
}

.relative {
  position: relative;
}

.flex {
  -webkit-flex: 1; /* Safari 6.1+ */
  -ms-flex: 1; /* IE 10 */
  flex: 1;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}

.ant-tabs-bar {
  border: 0px !important;
}

.ant-switch:focus {
  box-shadow: 0 0 0 2px rgba(60, 182, 36, 1) !important;
}

.system-blur {
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
}

.editor-wrapper {
  border: 1px solid red;
  height: 100% !important;
}

.editor-page {
  background-color: rgb(255, 255, 255);
  width: 794px;
  border: 1px solid green;
  height: 1123px;
  border: 0;
  margin: 3.75pt;
  margin-bottom: 7.5pt;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;
}

.rdw-storybook-toolbar-absolute {
  position: absolute;
  top: -130px;
}
.rdw-storybook-wrapper-margined {
  margin-top: 100px;
  position: relative;
}

.rdw-storybook-root {
  margin: 50px;
  width: 90%;
}
.rdw-storybook-editor {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
  height: 200px;
}
.rdw-storybook-toolbar {
  top: -130px;
}
.rdw-storybook-textarea {
  margin-top: 20px;
  resize: none;
  width: 100%;
  border: 1px solid #f1f1f1;
  height: 100px;
}

.btn-prev-secundary:hover,
.btn-prev-secundary:focus,
.btn-prev-red {
  border: 0px !important;
}
.btn-prev-secundary {
  border: 0px !important;
}
.ant-btn-default:hover,
.ant-btn-default:focus {
  /* border: 0px !important; */
}

.ant-btn-default {
  /* border: 0px !important; */
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 22px !important;
}

.anticon-menu-unfold {
  font-size: 22px;
}
.anticon-menu-fold {
  font-size: 22px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #f5f7fa !important;
  border-left: 6px solid #566274;
}

.ant-menu-item > .anticon {
  margin-left: -4px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > .anticon {
  margin-left: -10px !important;
}

.ant-menu-item-selected {
  color: #000 !important;
}

.ant-menu-item:hover {
  color: #ff7522 !important;
}

.orange-prev-hover:hover {
  color: #ff7522 !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: rgba(255, 117, 34, 0.1) !important;
}

.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 1px solid rgba(208, 208, 208, 0.4) !important;
}

.ant-skeleton-paragraph {
  padding: 0px;
}

.ant-tooltip-inner {
  background: #566274 !important;
  color: #fff !important;
  font-weight: 600;
  /* padding: 20px;   */
}
.ant-tooltip-arrow {
  /* border-top-color: #566274 !important; */
  border-right-color: #566274 !important;
}

.ant-select-dropdown-menu-item {
  white-space: normal !important;
}

.profissao-select-dropdown {
  width: 500px;
  left: 869px !important;
}

.profissao-select-dropdown .ant-select-dropdown-menu-item-group-title {
  height: auto !important;
}

.concomitancia-principal {
  background: rgba(60, 182, 36, 1);
  color: #fff;
}

.concomitancia-cursor {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
.editor-focused {
  background: #fbe41f;
  cursor: pointer;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.selectedWorkspaceText {
  color: #ff7522 !important;
}

.selectedWorkspaceBoldText {
  font-weight: 600 !important;
  color: #ff7522 !important;
}

.ant-form-item-label label {
  letter-spacing: 2.2px !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.58) !important;
  margin-bottom: 12px !important;
  text-transform: uppercase;
}

.upper {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
.button-no-margin-background {
  background: transparent !important;
  box-shadow: none !important;
  border: 0px !important;
}

.informacao_generica {
  background: #73bbd0;
}
.data_generica {
  background: #ae88bb;
}
.processo_numero_1o_grau {
  background: #cb787c;
}
.processo_numero_2o_grau {
  background: #cb787c;
}
.processo_numero_grau_superior {
  background: #cb787c;
}
.generic_info {
  background: #fbe41f;
}

.barraLateral {
  background: #d0d0d0;
  width: 10px;
  min-height: 100%;
}
.relatoioRmiTag {
  height: 24px;
  border-radius: 12px;
  background-color: #f5f7fa;
  border: solid 1px #dde5f0;
  text-align: center;

  height: 15px;
  font-family: SourceSansPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.ant-layout-header {
  background: #fff;
  height: 74px;
}

.ant-btn-primary {
  background-color: #3cb624;
  border-color: #3cb624;
}

/* .ant-input {
  height: 45px;
} */

.sb-avatar__text {
  border-radius: 3px;
}

.ant-menu-item {
  height: 65px !important;
  display: flex;
  align-items: center;
}

.btn-large {
  height: 49px;
}

.prev-destaque {
  color: #ff7522;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.prev-link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

.appContainer > div,
main {
  flex: 1;
}
.ant-table-header-column {
  width: 100%;
}
.ant-table-column-sorters {
  width: 100%;
}

.omni-searc-drawer {
  z-index: 900;
}

@media (max-width: 1366px) {
  .ant-layout-header {
    height: 64px;
  }

  .ant-layout-sider {
    /* flex: 0 0 210px !important;
    max-width: 210px !important;
    min-width: 210px !important;
    width: 210px !important; */
  }

  .ant-menu-item {
    height: 52px !important;
  }

  .ant-btn-default {
    width: 164px !important;
  }

  .logo-header {
    width: 138px !important;
  }
}

.placeholder-wrap {
  position: relative;
}
.placeholder-wrap .placeholder {
  position: absolute;
  top: 50%;
  left: 50px;
  color: #888;
  margin-top: -0.5em;
  line-height: 1em;
  z-index: 9;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.placeholder-wrap input {
  background-color: transparent;
  padding: 4px 6px;
  position: relative;
  z-index: 10;
}
.placeholder-wrap input:focus + .placeholder {
  display: none;
}
